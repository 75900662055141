export const ApiUrl = {
  //--- Account
  Login: "api/Account/Login",
  Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPassword: "api/Account/ResetPassword",
  ContactToAdmin: "api/cms/HomePage/ContactToAdmin",
  GetUserAccountDetail: "api/Account/GetUserAccountDetail",
  UpdateUserAccount: "api/Account/UpdateUserAccount",

  //Home Page
  SlideShow: "api/cms/HomePage/GetListPostHomePage",

  //Planning
  GetListStatement: "api/cms/Statement/GetListStatement",
  GetDetailStatement: "api/cms/Statement/GetDetailStatement",
  SearchPlanning: "api/admin/Planning/search-planning-by",
  PlanningType: "api/cms/Planning/get-all-planning-type",
  ApprovalAgencyLevel: "api/admin/ApprovalAgencyLevel/get-approval-agency-level",
  ServiceLink: "api/cms/Statement/GetAllServiceLink",
  SearchStatement: "api/cms/Statement/search-statement-by",
  PlanningTypeById: "api/cms/Planning/get-planning-type-by-id",
  PlanningSearchByIdName: "api/cms/Planning/search-planning-type-by-id-name",
  GetAllPlanningByTypeId: "api/cms/Planning/get-all-planning",
  GetPlanningById: "api/cms/Planning/get-planning-by-id",
  GetMapPlanningById: "api/cms/Planning/get-map-by-planning-id",
  GetPlanningRelationshipByPlanningId: 'api/cms/Planning/get-planning-relation-by-planning-id',
  GetBoundariesByPlanningId: 'api/cms/Planning/get-boundaries-by-planning-id',
  GetYearStatement: "api/cms/Planning/get-all-year-statment",
  GetAllBoundariesOfAllPlanning: 'api/cms/Planning/get-all-planning-boundaries',
  GetDetailPlanningById: 'api/cms/Planning/get-planning-by-id',

  //--- Map
  GetMapById: "api/admin/Map/get-map-by-id",
  MapFilter: "api/MapTools/MapFilter",
  GetDropDown: "api/MapTools/GetDropDown",
  SpatialSearch: "api/MapTools/SpatialSearch",

  //--- Consultant Community
  GetListConsultantCommunity: "api/cms/ConsultantCommunity/get-data-list-consultant-community",
  GetDetailConsultantCommunity: "api/cms/ConsultantCommunity/get-detail-consult-community",
  GetDetailFormTemplate: "api/cms/ConsultantCommunity/GetDetailFormTemplate",
  CreateFeedback: "api/cms/ConsultantCommunity/CreateFeedback",
  GetReportFeedback: "api/cms/ConsultantCommunity/GetReportFeedback",
  GetListExpiredForm: "api/cms/ConsultantCommunity/GetListForm",
  GetListResultConsultantCommunity: "api/cms/ConsultantCommunity/get-data-list-result-consultant-community",

  // Document
  DocumentType: "api/cms/Planning/get-all-document-type",
  SearchDocument: "api/cms/Planning/search-document-by",
  GetCmsLookupDistrict: "api/cms/Administrative/GetLookupDistrict",
  GetDocumentByPlanningId: "api/cms/Planning/get-document-by-planning-id",
  GetChildrenDocument: "api/cms/Planning/get-document-by-planning-id-parent-id",
  DownloadFile: "api/admin/DocumentUpload/DownloadFileBinary",
  GetLookupCommune: "api/admin/Administrative/GetLookupCommune"
};
