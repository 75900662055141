import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faCalendarAlt,
  faVideo,
  faFilm,
  faEye,
  faCamera,
  faQuestionCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import UrlCollect from "../../../common/url-collect";
import * as statementAction from "../../../redux/store/statement/statement.store";
import * as configuration from "../../../utils/configuration";

export default function MenuRightView(props) {
  const [serviceModels, setServiceModels] = useState(null);

  useEffect(() => {
    statementAction
      .GetSerivceLink()
      .then(
        (res) =>
          res &&
          res.content &&
          res.content.linkGroup &&
          setServiceModels(res.content.linkGroup)
      )
      .catch((err) => {});
  }, []);

  return (
    <div className="col-12 col-md-12 col-lg-4 pl-2 pr-2">
      <div className="header-result">
        <div className="title">
          <Link className="text-uppercase" to={UrlCollect.SyntheticPlanning}>
            BẢN ĐỒ TỔNG HỢP QUY HOẠCH
          </Link>
        </div>
      </div>

      <div className="content-result">
        <div className="item">
          <div className="row">
            <div className="col-12">
              <img
                src={require("../../../assets/image/map-default.png")}
                alt="Map"
                className="img-fluid w-100 mb-2 mb-md-0"
              />
            </div>
          </div>
        </div>

        {serviceModels &&
          serviceModels.map((items, index) =>
            items.displayType === "IMAGE" ? (
              <div className="item mb-1">
                {items.linkService &&
                  items.linkService.length > 0 &&
                  items.linkService.map((linkService, index1) => (
                    <div className="row" key={index1}>
                      <div className="col-12">
                        <a
                          href={linkService.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={
                              configuration.APIUrlDefault + linkService.image
                            }
                            alt="Map"
                            className="img-fluid w-100 mb-2 mb-md-0"
                          />
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div>
                <div className="header-result">
                  <div className="title">
                    <a
                      className="text-uppercase"
                      data-toggle="collapse"
                      href={"#Parent" + index}
                      role="button"
                      aria-expanded="false"
                      aria-controls="department"
                    >
                      <FontAwesomeIcon
                        icon={faAngleDoubleDown}
                        className="mr-2"
                      />
                      {items.name}
                    </a>
                  </div>
                </div>
                {items.linkService &&
                  items.linkService.length > 0 &&
                  items.linkService.map((itemsChild) => (
                    <div className="collapse" id={"Parent" + index}>
                      <div className="content-result pt-2 pb-2 mt-0">
                        <ul className="m-0">
                          <li>
                            <a
                              href={itemsChild.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {itemsChild.name}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
              </div>
            )
          )}

        <div className="menu-item-link">
          <ul className="list-unstyled">
            <li>
              <a href="http://uongbi.quangninh.gov.vn" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                Cổng thông tin điện tử
              </a>
            </li>
            <li>
              <a href="http://uongbi.gov.vn/video-p17c81" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
                Truyền hình Uông Bí
              </a>
            </li>
            <li>
              <a href="http://uongbi.gov.vn/phat-thanh-uong-bi" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
                Phát thanh Uông Bí
              </a>
            </li>
            <li>
              <a href="http://www.qtv.vn" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faFilm} />
                </span>
                Truyền Hình Quảng Ninh
              </a>
            </li>
            <li>
              <a href="http://baoquangninh.com.vn" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faNewspaper} />
                </span>
                Báo Quảng Ninh
              </a>
            </li>
            <li>
              <a href="http://dulichuongbi.vn" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEye} />
                </span>
                Du lịch Uông Bí
              </a>
            </li>
            <li>
              <a href="http://uongbi.gov.vn/hinh-anh-p18c30" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCamera} />
                </span>
                Hình Ảnh
              </a>
            </li>
            <li>
              <a
                href="http://uongbi.gov.vn/lich-lam-viec-p27c62"
                target="_blank"
              >
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                Lịch công tác
              </a>
            </li>
            <li>
              <a href="http://uongbi.gov.vn/hoi-dap" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
                Hỏi đáp trực tuyến
              </a>
            </li>
            <li>
              <a
                href="http://uongbi.gov.vn/van-ban-qppl-p15c82"
                target="_blank"
              >
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
                Văn bản pháp quy
              </a>
            </li>
            <li>
              <a
                href="http://uongbi.gov.vn/van-ban-qppl-p1060c1357"
                target="_blank"
              >
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
                VB chỉ đạo điều hành
              </a>
            </li>
            <li>
              <a href="http://uongbi.gov.vn/hom-thu-gop-y" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                Hòm thư góp ý
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
