import React from "react";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Vilanguage from "../../languages/vi";

const LanguageDisplay = Vilanguage;

export default class FooterView extends React.Component {
  render() {
    return (
      <div className="footer-container">
        <div className="container">
          <h5 className="text-center text-uppercase mb-3">
            {LanguageDisplay.footer_titleName}
          </h5>
          <div className="mb-1">
            <FontAwesomeIcon
              icon={faMapMarkedAlt}
              color="white"
              className="mr-2"
            />{" "}
            Địa chỉ: <span>{LanguageDisplay.footer_address}</span>
          </div>
          <div className="mb-1">
            <FontAwesomeIcon icon={faPhoneAlt} color="white" className="mr-2" />{" "}
            <span>
              Hotline: {LanguageDisplay.footer_hotline}/Fax:{" "}
              {Vilanguage.footer_fax}
            </span>
          </div>
          <div>
            <FontAwesomeIcon icon={faEnvelope} color="white" className="mr-2" />{" "}
            <span>E-mail: {LanguageDisplay.footer_email}</span>
          </div>
        </div>
      </div>
    );
  }
}
